import type { ComponentType } from "react"
import { addPropertyControls, ControlType } from "framer"
import React, { useEffect, useState } from "react"

//SET THE DEFAULT THEME HERE
function getDefaultTheme() {
    return "dark" //SET IT TO "dark" OR "light"
}

function setInitialState() {
    const storedTheme = localStorage.getItem("currentToggleState")
    if (storedTheme !== null) {
        return storedTheme === "dark"
    }

    const defaultTheme = getDefaultTheme()
    const currentToggleState = defaultTheme === "dark"
    localStorage.setItem(
        "currentToggleState",
        currentToggleState ? "dark" : "light"
    )

    if (typeof document !== "undefined") {
        const styleTag = document.getElementsByTagName("style")[0]
        const newCSS = styleTag.innerHTML.replace(
            /prefers-color-scheme: \w+/,
            `prefers-color-scheme: ${
                (currentToggleState && defaultTheme === "dark") ||
                (!currentToggleState && defaultTheme === "light")
                    ? "dark"
                    : "light"
            }`
        )
        styleTag.innerHTML = newCSS
    }

    return !currentToggleState
}

export function themeSwicther(Component): ComponentType {
    return (props) => {
        const [isOn, setIsOn] = useState(setInitialState())

        const toggle = () => {
            const newToggleState = !isOn ? "dark" : "light"
            setIsOn(!isOn)
            localStorage.setItem("currentToggleState", newToggleState)
            if (typeof window !== "undefined") {
                window.dispatchEvent(new CustomEvent("themeChange"))
            }
        }

        useEffect(() => {
            if (typeof window !== "undefined") {
                const mediaQuery = window.matchMedia(
                    "(prefers-color-scheme: dark)"
                )
                mediaQuery.addListener((e) => {
                    const newTheme = e.matches ? "dark" : "light"
                    localStorage.setItem("defaultTheme", newTheme)
                    localStorage.setItem("currentToggleState", newTheme)
                    setIsOn(newTheme === "dark")

                    if (typeof document !== "undefined") {
                        const styleTag =
                            document.getElementsByTagName("style")[0]
                        const newCSS = styleTag.innerHTML.replace(
                            /prefers-color-scheme: \w+/,
                            `prefers-color-scheme: ${
                                (newTheme === "dark" && newTheme === "dark") ||
                                (newTheme === "light" && newTheme === "light")
                                    ? "dark"
                                    : "light"
                            }`
                        )
                        styleTag.innerHTML = newCSS
                    }

                    window.dispatchEvent(new CustomEvent("themeChange"))
                })
            }
        }, [])

        useEffect(() => {
            if (
                typeof window !== "undefined" &&
                typeof document !== "undefined"
            ) {
                const currentToggleState = isOn ? "dark" : "light"
                const defaultTheme =
                    localStorage.getItem("defaultTheme") || getDefaultTheme()

                const styleTag = document.getElementsByTagName("style")[0]
                const newCSS = styleTag.innerHTML.replace(
                    /prefers-color-scheme: \w+/,
                    `prefers-color-scheme: ${
                        (currentToggleState === "dark" &&
                            defaultTheme === "dark") ||
                        (currentToggleState === "light" &&
                            defaultTheme === "light")
                            ? "dark"
                            : "light"
                    }`
                )
                styleTag.innerHTML = newCSS

                localStorage.setItem("currentToggleState", currentToggleState)
            }
        }, [isOn])

        const [isClient, setIsClient] = useState(false)

        useEffect(() => {
            setIsClient(true)
        }, [])

        return <Component {...props} onClick={toggle} />
    }
}
